/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SupplierStore
 */
export interface SupplierStore {
    /**
     * Required for removal; optional for addition
     * @type {string}
     * @memberof SupplierStore
     */
    id: string;
    /**
     * Required for addition; an identifier for the store
     * @type {string}
     * @memberof SupplierStore
     */
    customerCode?: string;
}

/**
 * Check if a given object implements the SupplierStore interface.
 */
// @ts-ignore
export function instanceOfSupplierStore(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

// @ts-ignore
export function SupplierStoreFromJSON(json: any): SupplierStore {
    return SupplierStoreFromJSONTyped(json, false);
}

// @ts-ignore
export function SupplierStoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplierStore {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'customerCode': !exists(json, 'customer_code') ? undefined : json['customer_code'],
    };
}

// @ts-ignore
export function SupplierStoreToJSON(value?: SupplierStore | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'customer_code': value.customerCode,
    };
}

