import {Configuration} from '@/api';
import {toApiStatus} from '@/api/mappers/mapStatus';
import {toSupplier} from '@/api/mappers/mapSupplier';
import {SuppliersApi as ApiSuppliersApi, GetDeliveryWindowsRequest, UpdateSupplierStoresRequest} from '@/gen/api';
import {DeliveryWindow, DeliveryWindowImpl} from '@/models/DeliveryWindow';
import {Supplier, SupplierCreate, Suppliers, SupplierStore, SupplierUpdate} from '@/models/Supplier';

export interface SupplierFilters {
    createdByStoreId: string;
}

interface SuppliersApi {
    listSuppliers(offset?: number, limit?: number): Promise<Suppliers>;
    findSuppliers(query?: string, filters?: Partial<SupplierFilters>, offset?: number, limit?: number): Promise<Suppliers>;
    getSupplier(id: string): Promise<Supplier | undefined>;
    getSuppliers(ids: string[]): Promise<Supplier[] | undefined>;
    createSupplier(supplier: SupplierCreate): Promise<void>;
    updateSupplier(id: string, supplier: SupplierUpdate): Promise<void>;
    deleteSupplier(id: string): Promise<void>;
    getDeliveryWindows(supplierId: string | string[], storeId: string, lookAheadInWeeks?: number): Promise<DeliveryWindow[]>;
    addStoresToSupplier(supplierId: string, stores: SupplierStore[]): Promise<void>;
    removeStoresFromSupplier(supplierId: string, stores: SupplierStore[]): Promise<void>;
}

export class SuppliersApiImpl implements SuppliersApi {
    private suppliersApi: ApiSuppliersApi;

    constructor(configuration: Configuration) {
        this.suppliersApi = new ApiSuppliersApi(configuration);
    }

    async listSuppliers(offset: number = 0, limit: number = 10): Promise<Suppliers> {
        try {
            const response = await this.suppliersApi.listSuppliers({
                offset,
                limit,
            });
            return {
                totalCount: response.totalCount,
                estimatedTotalCount: response.estimatedTotalCount,
                items: response.items.map(toSupplier),
            };
        } catch (error) {
            console.error('Error listing suppliers:', error);
            throw error;
        }
    }

    async findSuppliers(query?: string, filters?: Partial<SupplierFilters>, offset?: number, limit?: number): Promise<Suppliers> {
        try {
            const response = await this.suppliersApi.listSuppliers({
                query,
                limit,
                offset,
                createdByStoreId: filters?.createdByStoreId,
            });
            return {
                totalCount: response.totalCount,
                estimatedTotalCount: response.estimatedTotalCount,
                items: response.items.map(toSupplier),
            };
        } catch (error) {
            console.error('Error finding suppliers:', error);
            throw error;
        }
    }

    async getSupplier(id: string): Promise<Supplier | undefined> {
        try {
            const response = await this.suppliersApi.getSupplier({id});
            return toSupplier(response);
        } catch (error) {
            console.error('Error getting supplier:', error);
            throw error;
        }
    }

    async getSuppliers(ids: string[]): Promise<Supplier[] | undefined> {
        try {
            const response = await this.suppliersApi.listSuppliers({supplierId: ids});
            return response.items.map(toSupplier);
        } catch (error) {
            console.error('Error getting suppliers:', error);
            throw error;
        }
    }

    async createSupplier(supplier: SupplierCreate): Promise<void> {
        try {
            await this.suppliersApi.createSupplier({
                supplierCreateRequest: {
                    ...supplier,
                    status: toApiStatus(supplier.status),
                },
            });
        } catch (error) {
            console.error('Error creating supplier:', error);
            throw error;
        }
    }

    async updateSupplier(id: string, supplier: SupplierUpdate): Promise<void> {
        try {
            await this.suppliersApi.updateSupplier({
                id,
                supplierUpdateRequest: {
                    ...supplier,
                    status: toApiStatus(supplier.status),
                },
            });
        } catch (error) {
            console.error('Error updating supplier:', error);
            throw error;
        }
    }

    async deleteSupplier(id: string): Promise<void> {
        try {
            await this.suppliersApi.deleteSupplier({id});
        } catch (error) {
            console.error('Error deleting supplier:', error);
            throw error;
        }
    }

    async getDeliveryWindows(supplierId: string | string[], storeId: string, lookAheadInWeeks?:number): Promise<DeliveryWindow[]> {
        try {
            const request: GetDeliveryWindowsRequest = {
                storeId: storeId,
                supplierId: Array.isArray(supplierId) ? supplierId : [supplierId],
                lookAheadInWeeks: lookAheadInWeeks,
            };
            const response = await this.suppliersApi.getDeliveryWindows(request);
            return response.deliveryWindows.map(DeliveryWindowImpl.fromObject);
        } catch (error) {
            console.error('Error getting delivery windows:', error);
            throw error;
        }
    }

    async addStoresToSupplier(supplierId: string, stores: SupplierStore[]): Promise<void> {
        return await this.updateSupplierStores(supplierId, stores, 'add');
    }

    async removeStoresFromSupplier(supplierId: string, stores: SupplierStore[]): Promise<void> {
        return await this.updateSupplierStores(supplierId, stores, 'remove');
    }

    private async updateSupplierStores(
        supplierId: string,
        stores: SupplierStore[],
        operation: 'add' | 'remove',
    ): Promise<void> {
        try {
            const request: UpdateSupplierStoresRequest = {
                id: supplierId,
                updateSupplierStores: {
                    stores: stores
                        .filter(store => store.id !== undefined)
                        .map(store => ({
                            id: store.id as string,
                            customerCode: store.customerCode,
                        })),
                    operation: operation,
                },
            };
            return await this.suppliersApi.updateSupplierStores(request);
        } catch (error) {
            console.error(`Error updating supplier stores (${operation}):`, error);
            throw error;
        }
    }
}

export default SuppliersApi;
