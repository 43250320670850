import {Customer} from '@/models/Customer';
import {DayOfTheWeek} from '@/models/DayOfTheWeek';
import {Geolocation} from '@/models/Geolocation';
import {Status} from '@/models/Status';
import {Time} from '@/models/Time';
import {Warehouse} from '@/models/Warehouse';

type customData = { [key: string]: string };
type dynamicFields = { [key: string]: string };

export enum StoreAssociation {
    CUSTOMER = 'customer',
    WAREHOUSE = 'warehouse'
}

export interface Store {
    id: string;
    code: string;
    customerId: string;
    customerCode: string;
    name: string;
    email: string;
    externalCode?: string;
    contactNumber: string;
    description: string;
    status: Status;
    addresses: StoreAddress[];
    receivingWindows: StoreReceivingWindow[];
    createdAt: Date;
    customData: customData;
    dynamicFields: dynamicFields;
    customer?: Customer;
    warehouse?: Warehouse;
}

export enum StoreAddressType {
    BUSINESS = 'business',
    BILLING = 'billing',
    DELIVERY = 'delivery',
    UNKNOWN = 'unknown'
}

export interface StoreAddress {
    id?: string;
    storeId: string;
    type: StoreAddressType;
    building: string;
    street1: string;
    street2: string;
    postalCode: string;
    region: string;
    city: string;
    countryCode: string;
    location: Geolocation;
    additionalDescription: string;
}

export interface StoreReceivingWindow {
    id?: string;
    storeId?: string;
    day: DayOfTheWeek;
    startTime: Time;
    endTime: Time;
}

export interface Stores {
    items: Store[];
    totalCount?: number;
    estimatedTotalCount?: number;
}

export interface StoreCreate {
    code: string;
    customerId: string;
    name: string;
    email: string;
    externalCode?: string;
    contactNumber: string;
    description: string;
    status: Status;
    addresses: StoreAddress[];
    receivingWindows: StoreReceivingWindow[];
    customData: customData;
    dynamicFields: dynamicFields;
}

export interface StoreUpdate {
    id: string;
    code: string;
    customerId: string;
    name: string;
    email: string;
    externalCode?: string;
    contactNumber: string;
    description: string;
    status: Status;
    addresses: StoreAddress[];
    receivingWindows: StoreReceivingWindow[];
    customData: customData;
    dynamicFields: dynamicFields;
}

export function findStoreAddress(addresses?: StoreAddress[], addressTypes: StoreAddressType[] = [StoreAddressType.BUSINESS]): StoreAddress | undefined {
    if (!addresses) {
        return;
    }
    for (const addressType of addressTypes) {
        const address = addresses.find(address => address.type === addressType);
        if (address) {
            return address;
        }
    }
    return;
}
