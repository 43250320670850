/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
// @ts-ignore
import type { SupplierStore } from './SupplierStore';
// @ts-ignore
import {
// @ts-ignore
    SupplierStoreFromJSON,
// @ts-ignore
    SupplierStoreFromJSONTyped,
// @ts-ignore
    SupplierStoreToJSON,
} from './SupplierStore';

/**
 * 
 * @export
 * @interface UpdateSupplierStores
 */
export interface UpdateSupplierStores {
    /**
     * 
     * @type {Array<SupplierStore>}
     * @memberof UpdateSupplierStores
     */
    stores: Array<SupplierStore>;
    /**
     * 
     * @type {string}
     * @memberof UpdateSupplierStores
     */
    warehouseId?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSupplierStores
     */
    operation: UpdateSupplierStoresOperationEnum;
}


/**
 * @export
 */
export const UpdateSupplierStoresOperationEnum = {
    Add: 'add',
    Remove: 'remove'
} as const;
export type UpdateSupplierStoresOperationEnum = typeof UpdateSupplierStoresOperationEnum[keyof typeof UpdateSupplierStoresOperationEnum];


/**
 * Check if a given object implements the UpdateSupplierStores interface.
 */
// @ts-ignore
export function instanceOfUpdateSupplierStores(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "stores" in value;
    isInstance = isInstance && "operation" in value;

    return isInstance;
}

// @ts-ignore
export function UpdateSupplierStoresFromJSON(json: any): UpdateSupplierStores {
    return UpdateSupplierStoresFromJSONTyped(json, false);
}

// @ts-ignore
export function UpdateSupplierStoresFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateSupplierStores {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stores': ((json['stores'] as Array<any>).map(SupplierStoreFromJSON)),
        'warehouseId': !exists(json, 'warehouse_id') ? undefined : json['warehouse_id'],
        'operation': json['operation'],
    };
}

// @ts-ignore
export function UpdateSupplierStoresToJSON(value?: UpdateSupplierStores | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'stores': ((value.stores as Array<any>).map(SupplierStoreToJSON)),
        'warehouse_id': value.warehouseId,
        'operation': value.operation,
    };
}

